$header-height: 70px;
$header-height-small: 100px;
$header-height-large: 100px;

.page-wrapper {
    padding-top: $header-height;
    @media (min-width: map-get($aui-breakpoint, 'medium')) {
        padding-top: $header-height-large;
    }
}

.header {
    height: $header-height;
    @media (min-width: map-get($aui-breakpoint, 'medium')) {
        height: $header-height-large;
    }
    & > .outer-wrapper {
        height: 100%;
        z-index: 100;
    }

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;

    .header-border {
        border-bottom: 1px solid;
        z-index: 20;
    }

    .header-inner {
        position: relative;
        z-index: 50;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        > .row {
            align-items: center;
        }
    }
    
    .nav-toggle-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .nav-toggle {
      cursor: pointer;
      position: relative;
      background: none;
      border: none;
      box-shadow: none;
      padding: 0;
      color: inherit;
      width: 24px;
      height: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 6px;
      transition: gap .5s;
      .line {
        border: 1px solid;
        display: block;
        width: 24px;
        transform-origin: center;
        transition: transform .5s, opacity .5s;
        &:nth-of-type(2) {
          position: absolute;
          top: calc(50% - 1px);
          width: 100%;
        }
      }
      &.open {
        gap: 0;
        .line {
          &:nth-of-type(1) {
            transform: rotate(45deg) translate(0,-1px);
          }
          &:nth-of-type(2) {
            opacity: 0;
          }
          &:nth-of-type(3) {
            transform: rotate(-45deg) translate(0,1px);
            margin-top: -3px;
          }
        }
      }
      &.opening {
        .line {
          transition-delay: .5s;
        }
      }
      &.closing {
        transition-delay: .5s;
      }
    }

    .title-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      a {
        display: inline-block;
      }
      svg {
        display: block;
        height: 21px;
        max-height: 1.6em;
        max-width: 50vw;
        width: 100%;
      }
    }
    
    .main-nav-desktop {
      display: flex;
      flex-direction: column;
      justify-content: center;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }
      li {
        display: inline;
        padding-left: 1rem;
      }
      a {
        color: $aui-color-gray40;
        white-space: nowrap;
        transition: color 0.3s $aui-easing;
        &:hover {
          color: $aui-color-black;
        }
      }
      @media (min-width: map-get($aui-breakpoint, 'large')) {
        // font-size: 1.2rem;
        li {
          padding-left: 1.8rem;
        }
      }
    }
    
    .login-wrapper {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 5px;
      .aui-button {
        font-family: $aui-font-extended;
        @media (max-width: map-get($aui-breakpoint, 'large') - 1px) {
          padding: .7em 2em;
          min-width: 7em;
          font-size: .9em;
        }
        @media (max-width: map-get($aui-breakpoint, 'medium') - 1px) {
          padding: .5em 1.2em;
          min-width: 5em;
          font-size: .8em;
        }
        @media (max-width: map-get($aui-breakpoint, 'small') - 1px) {
          font-size: .75em;
        }
      }
    }

    .user-wrapper {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: .5rem;
      .user-btn {
        cursor: pointer;
        color: $aui-color-gray40;
        .audiicon {
          transform: rotate(180deg);
          transition: transform .5s;
          transform-origin: center;
        }
        &.open .audiicon {
          transform: rotate(0deg);
        }
        .aui-button__text {
          max-width: 80px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          @media (max-width: map-get($aui-breakpoint, 'small') - 1px) { margin-right: 0; }
          @media (min-width: map-get($aui-breakpoint, 'xsmall')) { max-width: 180px; }
          @media (min-width: map-get($aui-breakpoint, 'small')) { max-width: 200px; }
          // @media (min-width: map-get($aui-breakpoint, 'medium')) { max-width: 200px; }
          @media (min-width: map-get($aui-breakpoint, 'large')) { max-width: 120px; }
          @media (min-width: map-get($aui-breakpoint, 'xlarge')) { max-width: 200px; }
        }
      }
      .nav-vehicle {
        position: relative;
        font-size: .8rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: $aui-color-gray40;
        span {
          width: 100px;
          display: block;
        }
        .nav-vehicle__jellybean {
          height: 42px;
          img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
        .nav-vehicle__text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          // display: none;
        }
      }
    }

    .main-nav-wrapper, .user-nav-wrapper {
        z-index: 10;
        width: 100%;
        max-width: map-get($aui-breakpoint, 'xlarge');
        height: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%,0);
        &.open { height: 100vh; }
    }
    
    .main-nav__overlay, .user-nav__overlay {
        z-index: 8;
        background: $aui-color-black;
        opacity: 0;
        transition: opacity 0.2s;
        &.open {
            opacity: 0.5;
            width: 100%;
            height: 100vh;
        }
    }
    
    .main-nav__panel, .user-nav__panel {
        z-index: 10;
        background: $aui-color-white;
        font-weight: $aui-font-weight-normal;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: transform 0.4s, opacity 0.2s;
        max-height: 100vh;
        overflow: auto;
        width: auto;
        height: auto;
        &.open {
            transform: translate(0, 0);
            opacity: 1;
        }
        
        ul {
            list-style: none;
            padding: 0 0 2rem;
            margin: 0 20px;
            min-width: 60vw;
            max-width: 90vw;
            @media (min-width: map-get($aui-breakpoint, 'small')) {
              margin: 0 40px;
              min-width: 37vw;
            }
            @media (min-width: map-get($aui-breakpoint, 'medium')) {
              min-width: 33vw;
              max-width: none;
            }
            @media (min-width: map-get($aui-breakpoint, 'large')) {
              min-width: 28vw;
            }
            @media (min-width: map-get($aui-breakpoint, 'xlarge')) {
              min-width: auto;
              width: 400px;
            }
            li {
                padding-top: 1rem;
                padding-bottom: 1rem;
                @media (min-width: map-get($aui-breakpoint, 'small')) {
                  padding-top: 1.6rem;
                  padding-bottom: 1.6rem;
                }
                &:not(:first-child) {
                    border-top: 1px solid $aui-color-gray85;
                }
            }
            button {
              background: none;
              border: none;
              box-shadow: none;
              padding: 0;
              font-family: inherit;
              color: inherit;
              cursor: pointer;
            }
            a {
              color: $aui-color-gray40;
              transition: color 0.3s $aui-easing;
              &:hover {
                color: $aui-color-black;
              }
            }
        }
    }
    
    .main-nav__panel {
      transform: translate(-100%, -100%);
      left: 0;
      .main-nav__header-back {
          height: 80px; //$header-height;
          position: sticky;
          top:0;
          width: 100%;
          background: $aui-color-white;
          z-index: 10;
          @media (min-width: map-get($aui-breakpoint, 'small')) {
              height: 100px; //$header-height-small;
          }
      }
    }

    .user-nav__panel {
        transform: translate(100%, -100%);
        right: 0;
        > * {
            padding-top: 100px;
        }
        ul {
          text-align: right;
        }
        .nav-vehicle {
          display: block;
          position: relative;
          padding-left: 150px;
          text-align: right;
          img {
            position: absolute;
            left: 20px;
            top: 40%;
            transform: translate(0,-50%);
            width: 120px;
          }
          span {
            display: block;
          }
          .rego {
            opacity: .8;
          }
        }
    }
}
