// styles for Section component
.sectionText {
  @media (min-width: map-get($aui-breakpoint, 'small')) {
    &.not-left {
      padding-left: $spacer;
    }
    &.not-right {
      padding-right: $spacer;
    }
  }
}

.tile {
  position: relative;
  overflow: hidden;
  height: 42vw;
  @media (min-width: map-get($aui-breakpoint, 'small')) {
    height: 24vw;
  }
  @media (min-width: map-get($aui-breakpoint, 'xlarge')) {
    height: 340px;
  }
  > a {
    display: block;
    height: 100%;
  }
  &.overlay > a::after {
    content: " ";
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.35) 90%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
  &.overlay:hover > a::after {
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 70%);
  }
  .tile__img {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    transition: transform 0.5s;
  }
  &:hover .tile__img {
    transform: scale(1.1);
  }
  .tile__text {
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 15px 15px;
  }
  .tile__title {
    font-weight: bold;
    &::before {
      display: block;
      position: absolute;
      top: -20px;
      transition: width 0.4s;
      background-color: currentColor;
      width: 0;
      height: 5px;
      content: '';
    }
  }
  .tile__subtitle {
    opacity: 0;
    margin-top: 0;
    height: 0;
    transition: opacity 0.4s, margin-top 0.4s, height 0.4s;
    font-weight: $aui-font-weight-normal;
    line-height: 1.5em;
  }
  &:hover {
    .tile__title {
      &::before {
        width: 14vw;
        max-width: 200px;
      }
    }
    .tile__subtitle {
      opacity: 1;
      margin-top: 5px;
      height: 3em;
    }
  }
}


.sectionQuote {
  height: 100%;
  .quote {
    width: 100%;
    height: 100%;
    padding: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .quote-inner {
      padding: 0 0 20px;
      border-bottom: 2px solid $aui-color-black;
    }
  }
}
.content .sectionQuote .quote-inner {
  width: 80%;
  @media (min-width: map-get($aui-breakpoint, 'small')) {
    width: 60%;
  }
}


.media-embed__background {
  background: $aui-color-black;
  video { width: 100%; }
}
.media-embed__width {
  max-width: 125vh;
  margin: 0 auto;
  .media-embed__height {
    width: 100%;
    height: 0;
    position: relative;
    padding: 56.25% 0 0 !important;
    iframe, video {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}
