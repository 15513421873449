.vehicles {
    .vehicles-list {
        .vehicle {
          position: relative;
          display: inline-block;
          background-color: $aui-color-gray95;
          background-size: cover;
          background-position: center;
          overflow: hidden;
          width: 100%;
          height: 0;
          padding-top: 70%;
          .vehicle-text {
            position: absolute;
            top: 0;
            left: 0;
          }
          .render {
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
          }
        }
    }
}

.vehicle {

    .upload-image {
        position: absolute;
        bottom: 0;
        right: 0;
        .aui-button {
          padding: 0.5em 0.8em;
          min-width: auto;
        }
    }

    .vehicle-detail {
        ul {
            list-style: none;
            padding-left: 0;

            li {
                border-top: 1px solid;

                &:last-child {
                    border-bottom: 1px solid;
                }
            }
        }
    }

    .trade-vehicle,
    .sold-vehicle {
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        @media (min-width: map-get($aui-breakpoint, 'xsmall')) and (max-width: map-get($aui-breakpoint, 'medium')) {
            .media-image .img {
              padding-top: 80%;
            }
        }
    }
}

.audi-store {
    .product {
        display: block;
        position: relative;
        padding: 10px 10px 30px;
        height: 100%;
        .product-name {
            font-size: .8rem;
            line-height: normal;
        }
        .aui-button--text {
            padding: 0;
            position: absolute;
            bottom: 0;
            left: 10px;
        }
    }
}

.upload-image-form {
    position: relative;
    width: 90%;
    max-width: 450px;
    margin: 0 auto;

    .cropped-image {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: auto;

        @media (min-width: map-get($aui-breakpoint, 'large')) {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }

    .size-error {
        color: $red;
    }

    .close {
      position: absolute;
      top: 10px;
      right: 0;
    }
}

.service-history {
    li {
        cursor: pointer;

        &.is-active,
        &:active,
        &:hover,
        &:visited {
            color: $aui-button-color-hover;
            stroke: $aui-button-color-hover;
            fill: $aui-button-color-hover;
        }
    }
}

// trade-in form
.rb-estimate {
  .rb-estimate-arrow {
    width: 100px;
    height: 1px;
    border-top: 1px solid;
    position: relative;
    display: inline-block;
    margin: 0 10px;
    vertical-align: middle;
    &::before, &::after {
      content: " ";
      width: 9px;
      height: 9px;
      border-style: solid;
      border-width: 1px 1px 0 0;
      display: block;
      position: absolute;
      top: -5px;
    }
    &::before {
      left: 1px;
      transform: rotate(225deg);
    }
    &::after {
      right: 1px;
      transform: rotate(45deg);
    }
  }
}
.rb-list__item {
  padding-top: 18px;
  padding-bottom: 20px;
  border-bottom: 1px solid $aui-color-gray80;
  cursor: pointer;
  .audiicon {
    visibility: hidden;
  }
  &:hover {
    background: $aui-color-gray95;
    .audiicon {
      visibility: visible;
    }
  }
}

.buy-service-plan {
    .service-plan-radios {
        .aui-radio {
            margin-bottom: 2em;
        }
        .aui-radio__label {
            line-height: 2.5em;
            margin-top: -0.4em;
            display: block;
            .aui-button {
                margin: 20px 0;
            }
        }
        .aui-radio__box {
            top: 2px;
        }
        .audiicon {
            vertical-align: middle;
        }
    }
    .form-section {
        position: relative;
        &.review {
            padding-right: 120px;
            max-width: 500px;
            &.editing {
                max-width: none;
            }
        }
        .review-edit-btn {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
    .tooltip sup .audiicon {
        width: 16px;
    }
    .plan-info {
        position: relative;
        .plan-info__close {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
        }
        &.active {
            padding-top: 50px;
            .plan-info__close {
                display: inline-block;
            }
        }
    }
    table.plan-inclusions {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        font-family: 'AudiTypeExtended', Verdana, Geneva, sans-serif;
        tr {
            vertical-align: top;
        }
        th, td {
            padding: 1rem 0.75rem 1rem 0;
            text-align: left;
            border-bottom: 1px solid rgba(0,0,0,0.1);
        }
    }
}
