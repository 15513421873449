// HeroSection
.hero {
  position: relative;
  background-color: $aui-color-gray90;
  scroll-margin-top: 68px;
  @include media-breakpoint-up(small) {
      scroll-margin-top: 84px;
  }

  .overlay {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    padding: 1rem 0;
    &.noimg {
      position: relative;
    }
  }

  .carousel-item {
    padding: 0;
    height: auto;
  }

  .media-image .img {
      padding-top: 75vh;
      @include media-breakpoint-up(small) {
          padding-top: 45%;
      }
  }
  .media-embed__width {
    max-width: 100%;
    .media-embed__height {
      padding-top: 75vh !important;
      @include media-breakpoint-up(small) {
          padding-top: 45% !important;
      }
    }
  }

  &.home .hero-content {
      justify-content: space-between;
      @include media-breakpoint-up(small) {
          justify-content: flex-start;
      }
      .hero-text {
          flex: 1 0 auto;
          @include media-breakpoint-up(small) {
              flex: 0 0 auto;
          }
          // @include media-breakpoint-up(medium) {
          //     width: 58%;
          // }
      }
      .hero-actions {
          @include media-breakpoint-down(xsmall) {
              .multi .aui-button {
                  width: calc(50% - 10px);
                  min-width: auto;
              }
          }
          @include media-breakpoint-up(small) {
              flex: 1 0 auto;
          }
          // @include media-breakpoint-up(medium) {
          //     width: 58%;
          // }
      }
      .scrolldown {
          text-align: center;
          button {
              cursor: pointer;
              background: transparent;
              border: 1px solid #fff;
              border-radius: 50%;
              color: #fff;
              padding: 0;
              width: 40px;
              height: 40px;
              svg {
                  display: block;
                  margin: 3px auto 0;
              }
          }
      }
  }
}

.hero.gradient-overlay, .vehicle-hero {
  .overlay {
    background: linear-gradient(165deg, rgba(0,0,0,0.67) 0%, rgba(0,0,0,0) 65%);
    color: $aui-color-light;
  }
}

.hero.fullheight {
  height: 100%;
  .carousel, .carousel-inner, .carousel-item, .media-image, .media-image .img {
    height: 100%;
  }
  .media-image .img {
    padding: 0;
  }
}

// HeroCarousel
.carousel-wrapper {
  position: relative;

  .overlay {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    flex-flow: column;
    padding: 1rem 0;
  }

  .carousel-item {
    padding: 0;
    height: auto;
  }
}

// HeroImage
.hero-image {
  position: relative;
  background-color: $aui-color-gray90;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    flex-flow: column;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include media-breakpoint-up(small) {
      // padding: $outer-offset;
    }
  }
}

.hero video.media-mp4 {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-home {
    background-color: $aui-color-black;
    margin-bottom: 50px;
    // .hero {
    //   background-color: $aui-color-black;
    //   video {
    //     max-width: 100%;
    //     max-height: 78vh;
    //   }
    // }
}

.vehicle-hero {
  height: 0;
  padding-top: 62%;
  overflow: hidden;
  @include media-breakpoint-up(tiny) {
    padding-top: 50%;
  }
  @include media-breakpoint-up(xsmall) {
    padding-top: 38.5%;
  }
  .background {
    transition: visibility 0.3s linear,opacity 0.3s linear;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .render {
    width: 100%;
    position: absolute;
    right: -10%;
    top: 18%;
    z-index: 5;
  }
  .overlay {
    z-index: 10;
  }
  .detail {
    .aui-button {
      padding: 0;
      svg {
        transition: transform .5s;
      }
    }
    .detail__text {
      max-height: 0;
      overflow: hidden;
      transition: max-height .5s;
    }
    &.show {
      .aui-button svg {
        transform: rotate(45deg);
      }
      .detail__text {
        max-height: 80px;
      }
    }
  }
}
