.hp-tile {
  position: relative;
  font-family: $aui-font-extended;
  text-align: left;
  padding: 0;
  border: none;
  p {
    font-size: .85rem;
    line-height: 1.5;
  }
  .aui-button__text {
    font-size: .8rem;
    &::after {
      top: 0.1em;
    }
  }
  .img {
    overflow: hidden;
  }
  img {
    display: block;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    transition: transform .5s $aui-easing;
  }
  &:hover {
    img {
      transform: scale(105%);
    }
  }
}
.hp-sidebar .hp-tile, .hp-main .img {
  @media (max-width: map-get($aui-breakpoint, 'medium') - 1px) {
    aspect-ratio: 50/29;
  }
  @media (min-width: map-get($aui-breakpoint, 'medium')) {
    height: 18vw;
  }
  @media (min-width: map-get($aui-breakpoint, 'xlarge')) {
    height: 260px;
  }
}
.hp-sidebar .hp-tile {
  @media (max-width: map-get($aui-breakpoint, 'small') - 1px) {
    aspect-ratio: 50/32;
  }
}
.hp-sidebar {
  @media (min-width: map-get($aui-breakpoint, 'medium')) {
    float: right;
    width: 23%;
    padding-left: 5px;
  }
  .hp-tile {
    overflow: hidden;
    background: #ccc;
    color: $aui-color-white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      font-size: .7rem;
    }
    .aui-button__text {
      font-size: .7rem;
    }
    .img {
      position: relative;
      overflow: hidden;
      flex: 1 1 auto;
    }
    h4 {
      padding: 15px;
      position: absolute;
      top: 0;
      left: 0;
      width: 85%
    }
    .hp-tile__overlay {
      position: absolute;
      inset: 0;
      background: linear-gradient(170deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 36%);
    }
    .hp-tile__text {
      position: relative;
      padding: 15px;
      background: $aui-color-black;
      &.has-icon {
        padding-left: 63px;
      }
      .audiicon {
        position: absolute;
        left: 10px;
        top: 15px;
        width: 42px;
        height: 42px;
      }
      @media (min-width: map-get($aui-breakpoint, 'medium')) and (max-width: map-get($aui-breakpoint, 'large') - 1px) {
        &.has-icon {
          padding-left: 50px;
        }
        .audiicon {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}
.hp-main {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 13px;
  @media (min-width: map-get($aui-breakpoint, 'medium')) {
    gap: 5px;
  }
  .hp-tile {
    flex: 1 1 50%;
    color: $aui-color-black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > * { flex: 0 1 auto; }
    > p { flex: 1 1 auto; }
  }
  .aui-headline-4 {
    @media (max-width: map-get($aui-breakpoint, 'medium')) {
      font-size: 1rem;
    }
  }
}
.home-hero {
  .carousel-item {
    background: #ccc;
    min-height: 340px;
    height: 55vh;
    .gradient-overlay, .hero-media, .media-embed__background, .media-embed__width, .media-embed__height, .media-image, .media-image .img {
      height: 100%;
      padding: 0;
    }
    @media (min-width: map-get($aui-breakpoint, 'small')) {
      height: 45vh;
    }
    @media (min-width: map-get($aui-breakpoint, 'medium')) {
      height: calc((18vw * 2) + 5px);
    }
    @media (min-width: map-get($aui-breakpoint, 'xlarge')) {
      height: calc((260px * 2) + 5px);
    }
    .multi {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 5px;
      flex-wrap: wrap;
    }
  }
  
}
.home.logged-in .home-hero {
  @media (min-width: map-get($aui-breakpoint, 'medium')) { width: 77%; }
}

.home > .content-wrapper {
  @media (max-width: map-get($aui-breakpoint, 'medium') - 1px) {
    display: flex;
    flex-direction: column;
    .home-hero {
      order: 1;
    }
    .hp-sidebar {
      order: 2;
    }
    .hp-main {
      order: 3;
    }
  }
}
