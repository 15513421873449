$env: 'production';
@import "variables";

@import "../audi-ui-components/styles/main";
@import "aui-overrides";

// bootstrap
$grid-breakpoints: map-merge((zero: 0), $aui-breakpoint);
$grid-gutter-width: 10px;
$spacer: 10px;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 3,
  7: $spacer * 4
);
$carousel-control-icon-width: 80px;
$carousel-control-prev-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%23fff' stroke-width='1' viewBox='0 0 48 48'%3e%3cpath d='M28.6 14.4L19.5 23.5 28.6 32.6'/%3e%3c/svg%3e");
$carousel-control-next-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%23fff' stroke-width='1' viewBox='0 0 48 48'%3e%3cpath d='M19.4 32.6L28.5 23.5 19.4 14.4'/%3e%3c/svg%3e");
@import 'bootstrap';

.col.section,
[class*='col-'].section {
  padding-bottom: $grid-gutter-width;
}

.aui-color-red {
  background-color: $aui-color-red;
}
.aui-color-text-red {
  color: $aui-color-red;
}

// outer grid
body {
  background: #f2f2f2;
  font-weight: 400;
}
img { max-width: 100%; }
$outer-padding: 40px;
.outer-wrapper {
  background: $aui-color-white;
  position: relative;
  width: 100%;
  max-width: map-get($aui-breakpoint, 'xlarge');
  margin: 0 auto;
}
.content-wrapper {
  padding-left: calc($outer-padding / 2);
  padding-right: calc($outer-padding / 2);
  @media (min-width: map-get($aui-breakpoint, 'small')) {
    padding-left: $outer-padding;
    padding-right: $outer-padding;
  }
  &.full-below-small {
    @media (max-width: map-get($aui-breakpoint, 'small') - 1px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &.full-below-medium {
    @media (max-width: map-get($aui-breakpoint, 'medium') - 1px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
// .page-wrapper {
// 
//   & > .container-fluid {
//     padding: 0;
//     @media (max-width: map-get($aui-breakpoint, 'small') - 1) {
//       & > .row {
//         margin-left: 0;
//         margin-right: 0;
//         & > .col,
//         & > [class*='col-'] {
//           padding-left: 0;
//           padding-right: 0;
//         }
//       }
//     }
//   }
// }
.outdent {
  margin-left: calc(($outer-padding / 2) * -1);
  margin-right: calc(($outer-padding / 2) * -1);
  @media (min-width: map-get($aui-breakpoint, 'small')) {
    margin-left: -($outer-padding);
    margin-right: -($outer-padding);
  }
}
.outdent-below-small {
  @media (max-width: map-get($aui-breakpoint, 'small') - 1px) {
    margin-left: calc(($outer-padding / 2) * -1);
    margin-right: calc(($outer-padding / 2) * -1);
  }
}
.outdent-below-medium {
  @media (max-width: map-get($aui-breakpoint, 'medium') - 1px) {
    margin-left: -($outer-padding);
    margin-right: -($outer-padding);
  }
  @media (max-width: map-get($aui-breakpoint, 'small') - 1px) {
    margin-left: calc(($outer-padding / 2) * -1);
    margin-right: calc(($outer-padding / 2) * -1);
  }
}
// .indent {
//   @media (min-width: map-get($aui-breakpoint, 'small')) {
//     width: 83.33333%;
//     max-width: 1120px;
//     margin-left: auto;
//     margin-right: auto;
//   }
// }

// text
i, em {
  font-style: italic;
}
b, strong {
  font-weight: $aui-font-weight-bold;
}

small {
  line-height: normal;
  display: block;
}

.cms-content {
  h1 {
    @extend .aui-headline-1;
    margin: 0 0 15px;
  }
  h2 {
    @extend .aui-headline-2;
    margin: 0 0 15px;
  }
  h3 {
    @extend .aui-headline-3;
    margin: 0 0 15px;
  }
  h4 {
    @extend .aui-headline-4;
    margin: 0 0 15px;
  }
  h5 {
    @extend .aui-headline-5;
    margin: 0 0 15px;
  }
  h6 {
    @extend .aui-headline-6;
    margin: 0 0 15px;
  }
  a:not(.aui-button) {
    @extend .aui-textlink;
    // max-width: 100%;
    // display: inline-block;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // line-height: normal;
    // vertical-align: bottom;
  }
  p, ul, ol {
    margin: 0 0 15px;
  }
}

.aui-font-extended {
    font-family: $aui-font-extended;
}

svg.wordmark {
  display: block;
  path {
    fill: currentColor;
  }
  &.audi-magazine {
    height: 28px;
  }
  &.ade {
    height: 44px;
  }
}
span.wordmark {
  display: inline-block;
  white-space: nowrap;
  font-weight: bold;
}

.disclaimer {
  font-size: 0.7rem;
  line-height: 1.8;
}

.headline-icon {
  position: relative;
  .audiicon-small {
    position: absolute;
    top: -1px;
    left: -32px;
  }
  .audiicon-large {
    position: absolute;
    top: -12px;
    left: -64px;
  }
}

iframe {
  width: 100%;
  min-height: 300px;
  border: none;
}

@import 'components/header';

@import 'components/footer';

@import 'components/section';

@import 'components/homepage';

.data-collection {
  font-size: 60%;
  line-height: 1.5em;
}

.server-error {
  color: $aui-color-red;
  line-height: normal;
  font-size: 95%;
}

.modal-form {
  max-width: 450px;
  margin: 0 auto;
}

.loader {
  text-align: center;
  &.overlay {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    &.gray20 { background: rgba($aui-color-gray20, 0.8); }
    &.gray90 { background: rgba($aui-color-gray90, 0.8); }
    &.gray95 { background: rgba($aui-color-gray95, 0.8); }
  }
  &.placeholder {
    position: relative;
    height: 0;
    padding-top: 42%;
  }
  .loader__inner {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
}
.loading-wrapper {
  position: relative; //the loading overlay height doesnt work without this.
}

.media-image {
  margin: 0;
  .img {
    height: 0;
    padding-top: 56.5%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &.wide {
      padding-top: 42.8571%;
    }
  }
}

.collapsible {
  position: relative;
  .visible-content {
    cursor: pointer;
  }
  .collapsible-content {
    max-height: 800px;
    transition: max-height 0.5s;
    overflow: hidden;
  }
  .collapsible-toggle {
    position: absolute;
    top: -20px;
    right: -14px;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    outline: none;
    cursor: pointer;
    .audiicon {
      transform: rotate(-90deg);
      transition: transform 0.5s;
    }
  }
  &.is-collapsed {
    .collapsible-content {
      max-height: 0;
    }
    .collapsible-toggle {
      .audiicon {
        transform: rotate(90deg);
      }
    }
  }
}

@import 'components/breadcrumbs';

.edit-btn {
  // used in .editable and also in event forms
  // there is no SystemEdit icon, so we are faking it here
  border: 1px solid;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  .audiicon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .edit-btn__text {
    text-indent: -999px;
    display: block;
  }
  position: relative;
  overflow: hidden;
}
.editable {
  position: relative;
  .title {
    position: relative;
    // font-weight: bold;
    margin: 0 0 ($spacer * 2);
  }
  .edit-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
  .fields {
    .subtitle {
      font-weight: bold;
      margin: $spacer 0;
    }
    .submit {
      text-align: right;
    }
    .field {
      &.helptext {
        margin: 0 0 $spacer;
      }
    }
    &.editing {
    }
    &.not-editing {
      .field {
        font-size: 90%;
        margin: 0 0 calc($spacer / 2);
        label {
          display: inline-block;
          vertical-align: top;
          width: 55%;
        }
        .value {
          text-align: right;
          display: inline-block;
          vertical-align: top;
          width: 45%;
          font-weight: bold;
        }
        &.hide-label {
          label {
            display: none;
          }
          .value {
            text-align: left;
            width: auto;
          }
        }
      }
    }
  }
}

.visible {
  visibility: visible !important;
  opacity: 1 !important;
}

@import 'components/carousel';

@import 'components/hero';

@import 'components/experience';
@import 'components/event';

@import 'components/faq';

@import 'components/profile';
@import 'components/finance';
@import 'components/cms-content';

@import 'components/vehicles';

@import 'components/datepicker';
@import 'components/upload-field';

@import 'components/multiples';

@import 'components/tooltip';

@import 'components/animation';
